.footer{
  border-top: 1px solid gray;
  height: 3.5rem;
}

@media screen and (max-device-width: 767px){

    .container{
     flex-direction: column-reverse;
    }

}